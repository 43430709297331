import React, { useState, useRef } from "react";
import html2canvas from "html2canvas";
import "./TypeAndDisplay.css";
import switchImage from "./data/images/switch.png";

function TypeAndDisplay() {
  const divRef = useRef(null);
  const [mode, setMode] = useState("words");
  const [inputValue, setInputValue] = useState("");
  const [originalWords, setOriginalWords] = useState([]);
  const [selectedType, setSelectedType] = useState(1);
  const [hoveredLetterIndex, setHoveredLetterIndex] = useState(null);

  const handleTypeClick = (index) => {
    setSelectedType(index);
  };
  const hebrewMapping = {
    // English (Latin) to Hebrew
    A: "א",
    B: "ב",
    C: "כ",
    D: "ד",
    E: "ע",
    F: "פ",
    G: "ג",
    H: "ה",
    I: "י",
    J: "י",
    K: "כ",
    L: "ל",
    M: "מ",
    N: "נ",
    O: "ע",
    P: "פ",
    Q: "ק",
    R: "ר",
    S: "ס",
    T: "ת",
    U: "ו",
    V: "ו",
    W: "ו",
    X: "קס",
    Y: "י",
    Z: "ז",

    // Greek to Hebrew
    Α: "א",
    Β: "ב",
    Γ: "ג",
    Δ: "ד",
    Ε: "ע",
    Ζ: "ז",
    Η: "ח",
    Θ: "ט",
    Ι: "י",
    Κ: "כ",
    Λ: "ל",
    Μ: "מ",
    Ν: "נ",
    Ξ: "קס",
    Ο: "ע",
    Π: "פ",
    Ρ: "ר",
    Σ: "ס",
    Τ: "ת",
    Υ: "ו",
    Φ: "פ",
    Χ: "כ",
    Ψ: "פס",
    Ω: "ע",

    Ά: "א",
    Έ: "ע",
    Ή: "ח",
    Ί: "י",
    Ό: "ע",
    Ύ: "ו",
    Ώ: "ע",
  };

  const handleSpecialCombinations = (text) => {
    text = text
      .replace(/CH/g, "ח") // "CH" as in "Chanukah"
      .replace(/SH/g, "ש") // "SH" as in "Shabbat"
      .replace(/PH/g, "פ") // "PH" as in "Pharaoh"
      .replace(/TS/g, "צ") // "TS" as in "Tzadik"
      .replace(/TZ/g, "צ") // Adding "TZ" variant
      .replace(/KH/g, "כ") // "KH" for "Khan"
      .replace(/AE/g, "א") // "AE" like "Aether" (not always needed)
      .replace(/TH/g, "ת") // For Greek "Θ"
      .replace(/OU/g, "ו") // Greek "ΟΥ" sounds like "U"
      .replace(/ΓΚ/g, "ג") // Greek "ΓΚ" sounds like "G"
      .replace(/ΓΓ/g, "נג") // "ΓΓ" usually sounds like "NG"
      .replace(/ /g, " "); // Keeping spaces intact

    return text;
  };

  const handleInputChange = (event) => {
    // Capitalize and remove tonos from Greek letters
    let text = event.target.value.toUpperCase();
    text = handleSpecialCombinations(text);

    setOriginalWords(text.split(" ")); // Store the original text
    const mapping = hebrewMapping;
    text = text
      .split("")
      .map((char) => mapping[char] || char)
      .join("");

    setInputValue(text);
  };

  const exportAsImage = async () => {
    const canvas = await html2canvas(divRef.current); // Capture the div content
    const image = canvas.toDataURL("image/png"); // Convert it to an image format (PNG)
    const link = document.createElement("a"); // Create an <a> element
    link.href = image; // Set the image as the href
    link.download = "transcribed_text.png"; // Set the default download name
    document.body.appendChild(link); // Append to the body temporarily
    link.click(); // Trigger the download
    document.body.removeChild(link); // Remove the link element
  };
  const shareImage = async () => {
    try {
      const canvas = await html2canvas(divRef.current);
      canvas.toBlob(async (blob) => {
        const file = new File([blob], "shared-image.png", {
          type: "image/png",
        });
        if (navigator.share) {
          try {
            await navigator.share({
              files: [file],
              title: "Shared Image",
              text: "Check out this image!",
            });
            console.log("Image shared successfully");
          } catch (error) {
            console.error("Error sharing the image:", error);
            alert("Error sharing the image. Please try again.");
          }
        } else {
          alert("Web Share API is not available in your browser.");
        }
      }, "image/png");
    } catch (error) {
      console.error("Failed to capture the image", error);
      alert("Failed to capture the image. Please try again.");
    }
  };

  const toggleMode = () => {
    setMode((prevMode) => (prevMode === "words" ? "letters" : "words"));
  };

  return (
    <div className="type-and-display">
      <h1 id="title">
        Μεταγραφή Λατινικών ή Ελληνικών Χαρακτήρων σε Εβραϊκούς
      </h1>
      <div className="transcibe-area">
        <div className="input-box">
          <textarea
            onChange={handleInputChange}
            placeholder="Πληκτρολόγησε σε Λατινικούς ή Ελληνικούς Χαρακτήρες..."
          ></textarea>
        </div>
        <div className="img-container">
          <img src={switchImage} alt="" />
        </div>
        <div className="transcribed">
          <div className="types">
            <div
              className={`type ${selectedType === 0 ? "selected" : ""}`}
              onClick={() => handleTypeClick(0)}
            >
              Solitreo γραφή
            </div>
            <div
              className={`type ${selectedType === 1 ? "selected" : ""}`}
              onClick={() => handleTypeClick(1)}
            >
              Τετράγωνη γραφή
            </div>
            {/* <div
              className={`type ${selectedType === 2 ? "selected" : ""}`}
              onClick={() => handleTypeClick(2)}
            >
              Rashi γραφή
            </div> */}
          </div>
          <div
            ref={divRef}
            className={`hebrew-text ${selectedType === 0 ? "solitreo" : ""} ${
              selectedType === 2 ? "rashi" : ""
            }`}
          >
            {mode === "words" &&
              inputValue.split(" ").map((word, index) => (
                <span
                  key={index}
                  className="word-div"
                  onMouseEnter={() => {
                    const wordDivs = document.querySelectorAll(".word-div");
                    wordDivs[index].textContent = originalWords[index];
                  }}
                  onMouseLeave={() => {
                    const wordDivs = document.querySelectorAll(".word-div");
                    wordDivs[index].textContent = word;
                  }}
                >
                  {word + " "}
                </span>
              ))}
            {mode === "letters" &&
              inputValue.split(" ").map((word, wordIndex) => (
                <span key={wordIndex} className="word-div">
                  {word.split("").map((letter, letterIndex) => {
                    // Generate a unique key for each letter based on its word and position
                    const uniqueLetterKey = `${wordIndex}-${letterIndex}`;

                    return (
                      <span
                        key={uniqueLetterKey}
                        className="letter-div"
                        onMouseEnter={() =>
                          setHoveredLetterIndex(uniqueLetterKey)
                        }
                        onMouseLeave={() => setHoveredLetterIndex(null)}
                      >
                        {/* Conditionally render the original letter if this letter is being hovered */}
                        {hoveredLetterIndex === uniqueLetterKey
                          ? originalWords[wordIndex][letterIndex]
                          : letter}
                      </span>
                    );
                  })}
                </span>
              ))}
          </div>
          <div className="svg-container">
            <button className="button-12" onClick={toggleMode}>{`${
              mode === "words" ? "Ανά γράμμα" : "Ανά λέξη"
            }`}</button>

            <svg
              className="svg"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              style={{ cursor: "pointer" }}
              onClick={() => {
                exportAsImage();
              }}
            >
              <path
                d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                stroke="#666666"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              className="svg"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              style={{ cursor: "pointer" }}
              onClick={() => {
                shareImage();
              }}
            >
              <path
                d="M7.15833 11.2583L12.85 14.575M12.8417 5.42501L7.15833 8.74167M17.5 4.16667C17.5 5.54738 16.3807 6.66667 15 6.66667C13.6193 6.66667 12.5 5.54738 12.5 4.16667C12.5 2.78596 13.6193 1.66667 15 1.66667C16.3807 1.66667 17.5 2.78596 17.5 4.16667ZM7.5 10C7.5 11.3807 6.38071 12.5 5 12.5C3.61929 12.5 2.5 11.3807 2.5 10C2.5 8.61929 3.61929 7.50001 5 7.50001C6.38071 7.50001 7.5 8.61929 7.5 10ZM17.5 15.8333C17.5 17.2141 16.3807 18.3333 15 18.3333C13.6193 18.3333 12.5 17.2141 12.5 15.8333C12.5 14.4526 13.6193 13.3333 15 13.3333C16.3807 13.3333 17.5 14.4526 17.5 15.8333Z"
                stroke="#666666"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TypeAndDisplay;
